<template>
    <div>
      <h2 class="text-4xl leading-tight text-center my-4">
        Concrete Report
      </h2>
      <div >       
        <dynamic-widget-component
          tab="structural-integrity-concrete"
        ></dynamic-widget-component>
        <dynamic-widget-component
          tab="concrete-batches"
        ></dynamic-widget-component>
      </div>
    </div>
  </template>
  
  <script>
  import DynamicWidgetComponent from "@/components/DynamicWidgetComponent";
  
  export default {
      name: "ConcreteReportPage",
      components: { DynamicWidgetComponent },
      mounted() {
          this.$logService.save(this.$options.name);
      },
      created() {
        console.log("ConcreteReportPage: Created");
        this.$store.dispatch("parseHash");
      },
  };
  </script>
  