<template>
    <h2 class="text-4xl leading-tight text-center m-auto text-bold">Alert Manager</h2>
    <div class="flex align-middle px-5">        
        <Button label="ALERT" icon="pi pi-plus-circle" iconPos="right"
                class="ml-auto" title="Alert" @click="fnAddNew"></Button>
    </div>
    <div class="px-5 py-3 flex-auto">
        <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
            <h3 class="text-indigo-600 text-center text-xl"
                style="text-transform: uppercase; font-weight: bold">
                ALERT RULES
            </h3>
            <DataTable :value="tableData" stripedRows class="w-full" size="small"
                       paginator :rows="10" :rowsPerPageOptions="[10, 20, 30, 40, 50, 100]"
                        sortField="createdAt" :sortOrder="-1"
                       v-model:filters="filters" filterDisplay="row" :loading="isLoading"
                       :globalFilterFields="filterFields">
                       <template #header>
                    <div class="text-end">
                        <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                    </div>
                </template>
                <template #empty>
                    No alerts found.
                </template>
                <template #loading>
                    Loading alerts data. Please wait...
                </template>
                <Column :exportable="false" bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" outlined rounded size="small" title="Edit"
                                class="dark:text-primary-300 dark:border-primary-300"
                                @click="fnEdit(slotProps.data.alertId)" />
                    </template>
                </Column>
                <Column :exportable="false" bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="slotProps">
                        <Button icon="pi pi-trash" outlined rounded severity="danger" size="small" title="Delete"
                                class="dark:text-red-300 dark:border-red-300"
                                @click="fnOpenConfBox(slotProps.data.alertId, slotProps.data.name)" />
                    </template>
                </Column>
                <Column :exportable="false" header="Start/Stop" bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="slotProps">
                        <img src="@/assets/loader-loading.gif" alt="Loading..." class="h-[24px] w-[24px]"
                             v-if="slotProps.data.loading" />
                        <Button icon="pi pi-stop" outlined rounded severity="danger" size="small" title="Stop"
                                class="dark:text-red-300 dark:border-red-300"
                                @click="fnStopAlert(slotProps.data.alertId)" v-else-if="slotProps.data.status == 1" />
                        <Button icon="pi pi-play" outlined rounded severity="success" size="small" title="Start"
                                class="dark:text-green-300 dark:border-green-300"
                                @click="fnStartAlert(slotProps.data.alertId)" v-else />
                    </template>
                </Column>
                <Column field="role" header="Role" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="{ data }">
                        {{ data.role }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="p-column-filter w-[7rem]" placeholder="Role" size="small" />
                    </template>
                </Column>
                <Column field="phase" header="Phase" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="{ data }">
                        {{ data.phase }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="p-column-filter w-[7rem]" placeholder="Phase" size="small" />
                    </template>
                </Column>
                <Column field="name" header="Alert" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="{ data }">
                        {{ data.name }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="p-column-filter w-[16rem]" placeholder="Search by name" size="small" />
                    </template>
                </Column>
                <Column field="responsible" header="Responsible" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="{ data }">
                        {{ data.responsible }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="p-column-filter w-[9rem]" placeholder="Responsible" size="small" />
                    </template>
                </Column>
                <Column field="createdAt" header="Created Date" sortable dataType="date" bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="{ data }">
                        {{ fnFormatDate(data.createdAt) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Calendar v-model="filterModel.value" dateFormat="dd M yy" placeholder="dd mmm yyyy" mask="99 aaa 9999" @date-select="filterCallback" inputClass="w-[10rem] p-2" />
                    </template>
                </Column>
                <Column :exportable="false" field="status" header="Status" sortable :showFilterMenu="false" bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="{ data }">
                        <Tag :value="data.status == 1 ? 'Started' : 'Stopped'" :severity="getSeverity(data.status)" />
                        <!--<Tag :value="slotProps.data.Status" :severity="getStatusLabel(slotProps.data.inventoryStatus)" />-->
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" optionLabel="text" optionValue="value"
                                  placeholder="Select" :showClear="false" inputClass="p-2 w-[6rem]">
                            <template #option="slotProps">
                                <Tag :value="slotProps.option.text" :severity="getSeverity(slotProps.option.value)" />
                            </template>
                        </Dropdown>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <LoaderComponent :isLoading="isLoading" type="primary"></LoaderComponent>
    <confirmation-component :msg="confirmObj.message"
                            :isConfirmationOpen="confirmObj.isOpen"
                            @close="fnCloseConfBox"
                            @ok="fnDelete"></confirmation-component>
</template>
<script>

    import LoaderComponent from "@/components/LoaderComponent.vue";
    import ConfirmationComponent from "@/components/ConfirmationComponent.vue";

    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import Button from 'primevue/button';
    import Dropdown from 'primevue/dropdown';
    import InputText from 'primevue/inputtext';
    import Calendar from 'primevue/calendar';
    import Tag from 'primevue/tag';
    
    import { ToastSeverity } from 'primevue/api';
    import { FilterMatchMode } from 'primevue/api';

    import { alertService } from "@/services/AlertService.js";
    import Constants from "@/utils/Constants.js";
    import { createCSV, downloadCSV } from "@/utils/utils";

    export default {
        name: "AlertManagerPage",
        components: {
            ConfirmationComponent,
            LoaderComponent,
            DataTable, Column, 
            Dropdown, Tag, Button, 
            InputText//, IconField, InputIcon
            , Calendar
    },
        data() {
            return {
                isLoading: false,
                tableData: [],
                confirmObj: {
                    message: "",
                    isOpen: false,
                    alertId: "",
                    alertName: ""
                },
                alerComp: {
                    alertId: undefined,
                    isOpen: false
                },
                connections: [],
                filterFields: ['role', 'phase', 'name', 'responsible', 'status', 'createdAt'],
                filters: {
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    role: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    phase: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    responsible: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    status: { value: null, matchMode: FilterMatchMode.EQUALS },
                    createdAt: { value: null, matchMode: FilterMatchMode.DATE_IS }
                },
                //statuses: ['Started', 'Stopped']
                statuses: [{ text: 'Started', value: 1 }, { text: 'Stopped', value: 0 }]
            };
        },
        created() {
            console.log("Alert Manager: Created");
            this.$store.dispatch("parseHash").then(() => { this.fnLoad(); });
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        unmounted() {
            console.log("Unmounting Alert Manager Page...");

            this.connections.forEach(function (item, index, arr) {

                var conn = item.conn;

                if (conn && conn.readyState == 1) {
                    console.log("Websocket connection closing...");
                    arr[index].conn.close();
                }
            });
        },
        methods: {
            exportCSV  () {
          this.loading = true        
          const additionalData = this.tableData
          const csv = createCSV(additionalData);          
          downloadCSV(csv, "ALERT RULES.csv");
          this.loading = false                              
        },     
            fnReceiveMessage(event) {
                console.log("An event has arrived - Start/Stop!!!");
                //console.log(event.data);
                let dssAlert = JSON.parse(event.data);

                if (dssAlert.action) {
                    console.log("dssAlert has an 'action'. Ignore it.");
                    return;
                }

                var icon = ToastSeverity.ERROR;
                if (dssAlert.success) {
                    if (dssAlert.started)
                        icon = ToastSeverity.SUCCESS;
                    else
                        icon = ToastSeverity.WARN;
                }
                
                //console.log(dssAlert.alertId);
                let alertId = dssAlert.alertId;
                
                const currentIndex = this.tableData.findIndex(p => p.alertId === alertId);

                //console.log(JSON.stringify(this.tableData));
                this.fnShowMessage('Event received!', dssAlert.message.replace(alertId, this.tableData[currentIndex].name), icon);

                //TODO: Update the table with the new data.
                this.tableData[currentIndex].loading = false;
                if (dssAlert.started)
                {
                    this.tableData[currentIndex].status = 1;
                    //TODO: The process is started!
                    // Send a message.
                }
                else if (dssAlert.stopped)
                {
                    this.tableData[currentIndex].status = 0;
                    //TODO: The process is stopped!
                    //send a message
                }

                var item = this.connections.find(e => e.id === alertId);
                if (item != undefined && item.conn != undefined && item.conn.readyState == 1) {
                    item.conn.onclose = (event) => {
                        console.log("Websocket connection closed...");
                        console.log(event);
                        this.connections.splice(this.connections.findIndex(e => e.id === alertId), 1);
                    };
                    console.log("Websocket connection closing...");
                    item.conn.close();
                }
            },
            fnStartAlert(alertId){
                this.fnStartStopAlert(alertId, Constants.AlertCommands.START);
            },
            fnStopAlert(alertId){
                this.fnStartStopAlert(alertId, Constants.AlertCommands.STOP);
            },
            fnStartStopAlert(alertId, action){
                //console.log(action + " Alert!");
                var data = {
                    alert_id: alertId,
                    action: action
                };
                let socket = new WebSocket(this.$manWS);
                
                socket.onmessage = this.fnReceiveMessage;
                socket.onopen = (event) => {
                    //console.log(event);
                    console.log("Successfully connected to the Management websocket server..." + JSON.stringify(event));
                    socket.send(JSON.stringify(data));
                };
                
                this.connections.push({ "id": alertId, "conn": socket });

                //TODO: Show a message that the command has been sent... or a small loading gift...
                const currentIndex = this.tableData.findIndex(p => p.alertId === alertId);
                this.tableData[currentIndex].loading = true;
            },
            fnLoad: function () {
                this.isLoading = true;
                this.tableData = [];
                //TODO: Call
                alertService.find(this.$store.getters.project)
                    .then((data) => {
                        //console.log(data);
                        if (data != undefined && Array.isArray(data)) {

                            data.forEach((currentValue) => {
                                //console.log(currentValue["createdAt"]);
                                if (currentValue["createdAt"] !== undefined && currentValue.createdAt.toString().length > 9) {
                                    currentValue["createdAt"] = new Date(currentValue["createdAt"].substring(0, 10));
                                }
                            });
                            this.tableData = data;
                        }
                        //console.log(this.tableData);
                        this.tableLoader = false;
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.fnShowToast('Error', 'Error when loading Alerts...!!!', ToastSeverity.ERROR);
                    });
                return;
            },
            fnOnAdd(alertSaved) {
                this.fnShowToast('Save Success', 'The alert "' + alertSaved.name + '" was inserted!', ToastSeverity.SUCCESS);
                //alert("Update Alert: " + alertId);
                this.tableData.push(alertSaved);
            },
            fnOnSave(alertNew) {
                this.fnShowToast('Save Success', 'The alert "' + alertNew.name + '" was updated!', ToastSeverity.SUCCESS);
                //alert("Update Alert: " + alertId);
                const currentIndex = this.tableData.findIndex(p => p.alertId === alertNew.alertId);

                this.tableData.splice(currentIndex, 1, alertNew);
            },
            fnOnDelete(alertId, alertName) {

                this.fnShowToast('Delete Success', 'The alert "' + alertName + '" was deleted!', ToastSeverity.WARN);
                //console.log('warning - The alert "' + alertName + '" was deleted!');

                const currentIndex = this.tableData.findIndex(p => p.alertId === alertId);
                this.tableData.splice(currentIndex, 1);
            },
            fnDelete() {
                this.loading = true;

                alertService.delete(this.confirmObj.alertId)
                    .then(() => {
                        //.then((data) => {
                        //console.log(data);
                        this.fnOnDelete(this.confirmObj.alertId, this.confirmObj.alertName);

                        this.confirmObj.message = "";
                        this.confirmObj.isOpen = false;
                        this.confirmObj.alertId = "";
                        this.confirmObj.alertName = "";

                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.fnShowToast('Error', 'An error occurred when trying to delete the alert "' + this.confirmObj.alertName + '"!!!', ToastSeverity.ERROR);
                        this.loading = false;
                    });
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnOpenConfBox: function (alertId, alertName) {
                this.confirmObj.alertId = alertId;
                this.confirmObj.alertName = alertName;
                this.confirmObj.message = "Do you really want to delete the alert: '" + alertName + "'?";
                this.confirmObj.isOpen = true;
            },
            fnCloseConfBox() {
                this.confirmObj.alertId = "";
                this.confirmObj.alertName = "";
                this.confirmObj.message = "";
                this.confirmObj.isOpen = false;
            },
            fnAddNew() {
                this.$router.push({ name: "preset-alert" });
                //this.alerComp.alertId = "";
                //this.alerComp.isOpen = true;
            },
            fnEdit: function (alertId) {
                //this.alerComp.alertId = alertId;
                //this.alerComp.isOpen = true;
                this.$router.push({
                    name: "alert-details",
                    params: { alertId: alertId }
                });
            },
            fnCloseAlertComp() {
                this.alerComp.isOpen = false;
                this.alerComp.alertId = "";
            },
            fnFormatDate(dateValue) {
                let result = new Date(2000, 0, 1);
                if (dateValue != undefined && dateValue instanceof Date) {
                    //console.log(dateValue.substring(0, 10));
                    result = dateValue;
                }
                
                return result.toLocaleString('en-GB', {
                    day: "2-digit", month: "short", year: "numeric"
                });
                //return result.toLocaleString('en-GB', { dateStyle: "short" });
            },
            getSeverity(status) {
                switch (status) {
                    case 0:
                        return 'danger';

                    case 1:
                        return 'success';

                    default:
                        return null;

                    /*case 'new':
                        return 'info';

                    case 'negotiation':
                        return 'warning';
                    */                        
                }
            }
        }
    };

</script>
