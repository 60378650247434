const STAGES = {
  PLANNING: "planning",
  PREPARATORY: "preparatory",
  SHOTCRETING: "shotcreting",
  FINISHING: "finishing",
  POSTWORK: "postwork",
};

const ROLES = {
  ADMIN: "admin",
  OPERATOR: "operator",
  SUPERVISOR: "supervisor",
  FOREMAN: "foreman",
  PROCUREMENT: "procurement",
  HEALTH_SAFETY: "healthsafety",
  HMI: "hmi",
};

const ALERT_COMMANDS = {
  START: "start",
  STOP: "stop",
};

const ALERT_SEVERITY = {
  CRITICAL: "critical",
  HIGH: "high",
  LOW: "low",
  WARNING: "warning",
};

const ALERT_STATUS = {
  NEW: "new", // 	Active alert indicating an ongoing issue.
  RESOLVED: "resolved", //	The issue has been resolved, and the alert is no longer active.
  ACKNOWLEDGED: "acknowledged", //	The alert has been acknowledged but not resolved.
  DISMISSED: "dismissed", //	Alert is suppressed due to various reasons.
  PENDING: "pending", //	No Data or insufficient data to determine the alert state.
};

const ALERT_FREQUENCY = {
  ALWAYS: { code: "always", text: "Always raise the alert." },
  KEEP_ONE_OPEN: { code: "keep_one_open", text: "Only keeps one alert open." },
  WAIT_UNTIL: {
    code: "wait_until",
    text: "Wait {X} minutes before raise again.",
  },
  MAINTAINED: {
    code: "maintained",
    text: "Raise an alert if a value is maintained for {X} minutes.",
  },
};

const ALERT_SEND_TYPE = {
  EMAIL: "email",
  PHONE_TEXT: "phone-text",
  WHATSAPP: "whatsapp",
};

const fnGetStage = function (byNumber) {
  let stage = "";
  switch (byNumber) {
    case 1:
      stage = Constants.Stages.PLANNING;
      break;
    case 2:
      stage = Constants.Stages.PREPARATORY;
      break;
    case 3:
      stage = Constants.Stages.SHOTCRETING;
      break;
    case 4:
      stage = Constants.Stages.FINISHING;
      break;
    case 5:
      stage = Constants.Stages.POSTWORK;
      break;
  }
  return stage;
};

const INCIDENT = {
  STATUS: {
    OPEN: "open", // 	Active alert indicating an ongoing issue.
    CLOSED: "closed", //	No Data or insufficient data to determine the alert state.
  },
  CLOSE_REASON: {
    CANCELLED: "cancelled",
    RESOLVED: "resolved", //	The issue has been resolved, and the alert is no longer active.
  },
  CATEGORIES: [
    { code: "quality", text: "Quality" },
    { code: "procurement", text: "Procurement" },
    { code: "healthsafety", text: "Health & Safety" },
    { code: "shotcreting", text: "Shotcreting" },
    { code: "other", text: "Other" },
  ],
  PRIORITIES: [
    { code: "critical", text: "Critical", value: 1 },
    { code: "high", text: "High", value: 2 },
    { code: "medium", text: "Medium", value: 3 },
    { code: "low", text: "Low", value: 4 },
  ],
  ROLES: [
    { code: "operator", text: "Operator" },
    { code: "supervisor", text: "Supervisor" },
    { code: "foreman", text: "Foreman" },
    //    { code: "procurement", text: "Procurement" },
    //    { code: "healthsafety", text: "Health & Safety" }
  ],
};

const INGEST_COMMANDS = {
  START: "start",
  STOP: "stop",
};

const COMPARISION_CONDITIONS = [
  //{ code:"empty", text: "", value: 0 },
  { code: "equal_to", text: "Equal to", value: 1 },
  { code: "not_equal", text: "Not equal to", value: 2 },
  { code: "greater", text: "Greater than", value: 3 },
  { code: "greater_equal", text: "Greater than equal to", value: 4 },
  { code: "less", text: "Less than", value: 5 },
  { code: "less_equal", text: "Less than or equal to", value: 6 },
  { code: "between", text: "Between", value: 7 },
  { code: "outside", text: "Outside of", value: 8 },
  { code: "is_true", text: "Is True", value: 9 },
  { code: "is_false", text: "Is False", value: 10 },
  { code: "inactive_minutes", text: "Inactive Minutes", value: 11 },
  { code: "equal_id", text: "Equal to Id", value: 12 },
];

const ASSETS = {
  DISPOSE_TYPE: {
    FAILED: "failed",
    END_LIFETIME: "lifetime-end",
  },
  TYPE: [
    { code: "pump", text: "Pump", value: 1 },
    { code: "hose", text: "Hose", value: 2 },
    //{ code: "robot", text: "Robot", value: 3 }
  ],
  TypeCode: {
    PUMP: "pump",
    HOSE: "hose",
    ROBOT: "robot",
  },
};

const STELLIO_ENTITIES = {
  JOBS: "Job",
  RECIPES: "ConcreteRecipe",
};

const Constants = {
  Roles: ROLES,
  Stages: STAGES,
  GetStage: fnGetStage,
  AlertCommands: ALERT_COMMANDS,
  AlertSeverity: ALERT_SEVERITY,
  AlertStatus: ALERT_STATUS,
  AlertFrequency: ALERT_FREQUENCY,
  AlertSendType: ALERT_SEND_TYPE,
  Incident: INCIDENT,
  Assets: ASSETS,
  IngestCommands: INGEST_COMMANDS,
  ComparisionConditions: COMPARISION_CONDITIONS,
  StellioEntities: STELLIO_ENTITIES,
};

export default Constants;
