<template>
  <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
    <div v-for="(item, idx) in data" :key="idx">
      <Card class="card rounded-none shadow-xl border-t-4 border-t-indigo-500">
        <template #title>
          <div class="text-base flex justify-between">
            <div>{{ item.name }}</div>
            <div>
              <span class="mr-2">
                <i
                  class="pi pi-circle-fill"
                  style="font-size: 12px"
                  :style="{
                    color:
                      item.status.toLowerCase().trim() == 'active'
                        ? 'green'
                        : 'red',
                  }"
                ></i
              ></span>
              <span>{{ item.status != undefined ? item.status.toUpperCase() : "" }}</span>
            </div>
          </div>
        </template>
        <template #content>
          <div style="" v-for="(col, colIdx) in cols" :key="colIdx">
            <div v-if="col !== 'name'">
            <div class="m-2 p-2 border-2 border-slate-200 rounded">
              <div class="pb-2 font-semibold uppercase">{{ col }}</div>
              <div class="w-100 pb-2 px-2" v-html="fnParseValue(item[col])" />
            </div>
          </div>
          </div> 
          <div v-if="activity[item.name] && activity[item.name].length > 0">            
            <div class="m-2 p-2 border-2 border-slate-200 rounded">                            
              <div class="flex items-center justify-between p-1 m-1">
              <h2 class="text-lg font-bold">Activities Log</h2>
              <Button icon="pi pi-angle-down" variant="text" rounded aria-label="Filter" size="small"  v-if="activityLogShow[item.name]" @click="toggleActivityLog(item.name)"/>
              <Button icon="pi pi-angle-right" variant="text" rounded aria-label="Filter" size="small"  v-else @click="toggleActivityLog(item.name)"/>
              </div>                                                      
            <DataTable :value="activity[item.name]" class="p-datatable-gridlines" paginator :rows="5" v-if="activityLogShow[item.name]">            
              <Column header="Date">
                <template #body="slotProps">
                  {{ slotProps.data.timestamp.split('.')[0] }}
                </template>
              </Column>
              <Column field="description" header="Activity" />            
              <Column header="Current/Next">
                <template #body="slotProps">
                  {{ slotProps.data.topic.split('/').pop() }}
                </template>
              </Column>
            </DataTable>
          </div>
          <div class="m-2 p-2 border-2 border-slate-200 rounded">
            <div class="flex items-center justify-between p-1 m-1">
              <h2 class="text-lg font-bold">Activities Done</h2>
              <Button icon="pi pi-angle-down" variant="text" rounded aria-label="Filter" size="small"  v-if="activityDoneShow[item.name]" @click="toggleActivityDone(item.name)"/>
              <Button icon="pi pi-angle-right" variant="text" rounded aria-label="Filter" size="small"  v-else @click="toggleActivityDone(item.name)"/>
              </div>                                 
            <DataTable :value="activityDone[item.name]" class="p-datatable-gridlines" v-if="activityDoneShow[item.name]" paginator :rows="5">            
              <Column field="description" header="Activities" />            
              <Column header="Start date">
                <template #body="slotProps">
                  {{ slotProps.data.time_start.split('.')[0] }}
                </template>
              </Column>
              <Column header="End date">
                <template #body="slotProps">
                  {{ slotProps.data.time_finish.split('.')[0] }}
                </template>
              </Column>                            
            </DataTable>
          </div>
          </div>          
        </template>        
      </Card>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Card from "primevue/card";
import { parseJsonValue } from "@/utils/ParseJson.js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from 'primevue/button';


export default {
  components: {
    Card,
    DataTable,
    Column,
    Button
  },
  props: {
    chartObject: Object,
    url: Object,
  },
  data() {
    return {      
      activity:[],  
      activityDone:[],    
      data: [],
      chart: "",
      dynamicColor: "white",
      conf: {},
      cols: [],    
      activityLogShow: {},  
      activityDoneShow: {}     
    };
  },
  watch: {
    chartObject: {      
      immediate: true,
      async handler(newValue) {                
        console.log("--------- New Value");                
        this.header = newValue.name;
        this.chart = newValue;  
        this.getData()                   
      },      
    },       
  },
  methods: {     
    toggleActivityLog(name) {        
      this.activityLogShow[name] = !this.activityLogShow[name];
    },
    toggleActivityDone(name) {
      this.activityDoneShow[name] = !this.activityDoneShow[name];
    },
    getData() {                     
      console.log("----- GETTING DATA OF MULTI CARDS WIDGET");                  
      axios
        .get(`${this.$apiBase}/${this.url}`)
        .then((response) => {                                                                              
          this.data = response.data.data;   
          this.activity = response.data.activityLog;             
          this.activityDone = response.data.activityDone;              
          this.conf = response.data.conf;
          this.cols = response.data.cols;             
          Object.entries(response.data.activityLog).map(([key]) => {
          this.activityLogShow[key] = false;
          this.activityDoneShow[key] = true;
        });
          
        })        
        .catch((error) => {
          console.error("Error fetching data:", error);
        });                       
      },      
      fnParseValue(text) {
          return parseJsonValue(text);
      },
  },
  
};
</script>
<style>
    .card {
        /*height: 450px;
   overflow: auto; */
    }
</style>
