<template>
    <div>
      <h2 class="text-4xl leading-tight text-center my-4">
        Robot Information Report
      </h2>
      <div >       
        <dynamic-widget-component
          tab="robot-info-data"
        ></dynamic-widget-component>       
      </div>
    </div>
  </template>
  
  <script>
  import DynamicWidgetComponent from "@/components/DynamicWidgetComponent";
  
  export default {
      name: "RobotInformationReportPage",
      components: { DynamicWidgetComponent },
      mounted() {
          this.$logService.save(this.$options.name);
      },
  };
  </script>
  